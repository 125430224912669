<template>
	<v-card
		flat
		:class="[
			'wr_grey_1',
			{ 'error-border-wr': prop_border && !agendas.length },
		]"
	>
		<v-card-text class="subtitle-1 font-weight-bold info--text px-0 pb-0">
			{{ $t("app.agenda") }}
		</v-card-text>
		<v-card-text v-if="agendas && agendas.length" class="pa-0">
			<v-card
				class="mt-3 agenda-border-card-wr rounded-xl"
				flat
				outlined
				v-for="(agenda, agendaIndex) in agendas"
				:key="agendaIndex"
			>
				<v-card-text>
					<v-row no-gutters>
						<v-col sm="6" class="title-col-wr">
							<TextBox
								v-model="agenda.title"
								:solo="false"
								:prop_value="agenda.title"
								:outlined="true"
								:disabled="submitted"
								placeholder="app.placeholder.write_agenda_title"
								@update-value="updateAgenda(agenda)"
							></TextBox>
						</v-col>
						<v-col class="text-end">
							<v-icon
								v-if="agendas.length > 1 && !submitted"
								color="error"
								@click="deleteAgenda(agenda.id)"
								>$vuetify.icons.values.close</v-icon
							>
						</v-col>
					</v-row>
					<v-row no-gutters class="mt-4">
						<v-col>
							<TextBox
								type="v-textarea"
								:rows="2"
								v-model="agenda.description"
								:solo="false"
								:prop_value="agenda.description"
								:outlined="true"
								:disabled="submitted"
								placeholder="app.placeholder.agenda_description"
								@update-value="updateAgenda(agenda)"
							></TextBox>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-card-text>
		<v-card-text v-if="!submitted"
			><v-icon large color="error" @click="createAgenda()"
				>$vuetify.icons.values.plus</v-icon
			></v-card-text
		>
	</v-card>
</template>

<script>
import TextBox from "@/components/plugins/TextBox";
import { findElIndex } from "@/utils/helpers";

export default {
	name: "MeetingAgenda",

	props: {
		submitted: {
			type: Boolean,
		},
		prop_border: {
			type: Boolean,
		},
	},

	data() {
		return {
			meeting_id: this.$route.params.id,
			agendas: [],
			tts_dialog: false,
			selected_text: null,
		};
	},

	components: {
		TextBox,
	},

	mounted() {
		this.listAgendas();
	},

	methods: {
		async listAgendas() {
			try {
				this.$loader.start();

				this.agendas = await this.$store.dispatch(
					"meetingSummary/listAgendas",
					{
						meeting_id: this.meeting_id,
					},
				);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async createAgenda() {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch(
					"meetingSummary/createAgenda",
					{
						title: this.$t("app.my_agenda"),
						description: null,
						meeting_id: this.meeting_id,
					},
				);

				this.agendas.push(response);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async updateAgenda(agenda) {
			try {
				await this.$store.dispatch("meetingSummary/updateAgenda", {
					agenda,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async deleteAgenda(agenda_id) {
			try {
				this.$loader.start();

				await this.$store.dispatch("meetingSummary/deleteAgenda", {
					agenda_id: agenda_id,
				});

				// Remove from local data too.
				let index = findElIndex(this.agendas, "id", agenda_id);
				if (index === -1) return;

				this.agendas.splice(index, 1);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
.agenda-border-card-wr {
	border: 3px solid var(--v-info-base) !important;
}
.error-border-wr {
	border: 1px solid var(--v-error-base) !important;
}
</style>
